import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Row, Col, Spinner } from 'reactstrap';
import axios from 'axios';
import { useAppSelector } from 'app/config/store';
import { useAppDispatch } from 'app/config/store';
import { getSession, loginSuccess } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';

interface AccessTokenObject {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  id_token: string;
}

export const CheckAuthCode: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [redirect, setRedirect] = useState<boolean>(false);
  const account = useAppSelector(state => state.authentication.account);
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const codeVerifier = urlParams.get('codeVerifier');

  function setAccessTokenLocalStorage(accessTokenObject: AccessTokenObject) {
    const { access_token, expires_in, refresh_token, id_token } = accessTokenObject;
    const expires_time = expires_in;
    const curr_Time = new Date().getTime().toString();
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('access_token_expiry', expires_time.toString());
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('expiryFrom', curr_Time);
    localStorage.setItem('id_token', id_token);
  }

  const getAccessToken = async (code: string | null) => {
    try {
      const apiUrl = `/auth/get/access/token?codeVerifier=null&code=${code}`;
      const response = await axios.get(apiUrl, {});
      await setAccessTokenLocalStorage(response.data);
      let AT = localStorage.getItem('access_token');
      if (response.data) {
        await dispatch(getSession());
        await dispatch(getProfile());
        await dispatch(loginSuccess());
      }
      if (AT) {
        setRedirect(true); // Set the redirect state to true after successfully fetching the access token
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401 || error.response.status === 500) {
          localStorage.clear();
          setRedirect(true);
        }
      }
    } finally {
      setLoading(false); // Set loading to false whether the request succeeds or fails
    }
  };

  useEffect(() => {
    if (code) {
      setLoading(true);
      getAccessToken(code);
    } else {
      setLoading(false);
    }
  }, [code, codeVerifier]);

  if (redirect) {
    return <Navigate to="/"></Navigate>;
  }

  if (loading) {
    return (
      <div className="token-loader-home">
        <div className="token-loader">
          <Spinner className="body-loader-icon"></Spinner>
        </div>
      </div>
    );
  }
  return (
    <div className="token-loader-home">
      <div className="token-loader">
        <Spinner className="body-loader-icon"></Spinner>
      </div>
    </div>
  );
};

export default CheckAuthCode;
