import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, FormGroup, Input, Label, Spinner, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesProfile } from 'app/entities/adminprofile/adminprofile.reducer';
import axios from 'axios';

export const UsersAdmin = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 10, 'id'), location.search)
  );
  const userProfileList = useAppSelector(state => state.adminProfile.entities);
  const loading = useAppSelector(state => state.adminProfile.loading);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isActive, setIsActive] = useState({});
  const baseImageUrl = '/profile/get/image';

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    if (userProfileList.content) {
      const activeState = {};
      userProfileList.content.forEach(user => {
        activeState[user.userId] = user.status?.toLowerCase() === 'active';
        //console.log(user.status, 'test');
      });
      setIsActive(activeState);
      // console.log('Initial isActive state:', activeState);

      setTotalUsers(userProfileList.totalElements || 0);
    }
  }, [userProfileList]);
  //console.log(isActive, 'testddddd');
  const getAllEntities = () => {
    dispatch(
      getEntitiesProfile({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
    userProfileList.totalElements != undefined ? setTotalUsers(userProfileList.totalElements) : '';
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const page = params.get('page');
  //   const sort = params.get(SORT);
  //   if (page && sort) {
  //     const sortSplit = sort.split(',');
  //     setPaginationState({
  //       ...paginationState,
  //       activePage: +page,
  //       sort: sortSplit[0],
  //       order: sortSplit[1],
  //     });
  //   }
  // }, [location.search]);
  useEffect(() => {
    // Update totalUsers when userProfileList changes
    if (userProfileList.totalElements !== undefined) {
      setTotalUsers(userProfileList.totalElements);
    }
  }, [userProfileList]);
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleToggleChange = async userId => {
    const newStatus = !isActive[userId];
    try {
      await axios.put(`api/update/active/${userId}`, null, {
        params: { isActive: newStatus },
      });
      setIsActive({ ...isActive, [userId]: newStatus });
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const getInitials = (name: string) => {
    return `${name.charAt(0)}`;
  };

  return (
    <Container className="admin-user-page card meeting-border">
      {loading ? (
        <Spinner className="body-loader-icon"></Spinner>
      ) : (
        <>
          <div>
            <h2 id="user-profile-heading" data-cy="UserProfileHeading">
              <Translate contentKey="fivePlusApp.userProfile.home.title">User Profiles</Translate>
              <div className="d-flex justify-content-end">
                {/* <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="fivePlusApp.userProfile.home.refreshListLabel">Refresh List</Translate>
            </Button> */}
              </div>
            </h2>
            <div className="table-responsive">
              {userProfileList.content && userProfileList.content.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="hand" onClick={sort('userName')}>
                        <Translate contentKey="fivePlusApp.userProfile.userName">Name</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={sort('isMentor')}>
                        <Translate contentKey="fivePlusApp.userProfile.role">Role</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={sort('status')}>
                        <Translate contentKey="fivePlusApp.userProfile.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={sort('email')}>
                        <Translate contentKey="fivePlusApp.userProfile.email">Email</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {userProfileList.content.map((userProfile, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        {/* <td>
                    <Button tag={Link} to={`/user-profile/${userProfile.id}`} color="link" size="sm">
                      {userProfile.id}
                    </Button>
                  </td> */}
                        <></>
                        <td>
                          <div className="me-3 connection-avtar">
                            {userProfile.profilePic ? (
                              <img src={`${baseImageUrl}/${userProfile.profilePic}`} alt="" />
                            ) : (
                              <div className="initials-avatar">{userProfile && userProfile.name ? getInitials(userProfile.name) : ' '}</div>
                            )}
                            <div className="user-name-occp">
                              <p>{userProfile.name && `${userProfile.name}`}</p>
                              <span> {userProfile.occupation || 'No occupation'}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={`${userProfile.isMentor ? 'Mentor' : 'Mentee'}`}>
                            {userProfile.isMentor ? 'Mentor' : 'Mentee'}
                          </div>
                        </td>
                        <td>
                          <FormGroup switch className="tabs-switch">
                            <Input
                              type="switch"
                              checked={!isActive[userProfile.userId]}
                              onChange={() => {
                                //console.log(`Current status of user ${userProfile.userId}:`, isActive[userProfile.userId]);
                                handleToggleChange(userProfile.userId);
                              }}
                            />
                            <Label className="mentor-label">Active</Label>
                            <Label className="mentee-label">Inactive</Label>
                          </FormGroup>
                        </td>
                        <td>
                          <div className="me-3 connection-avtar">{userProfile.email ? userProfile.email : ''}</div>
                        </td>
                        <td className="text-end">
                          <div className="btn-group flex-btn-group-container">
                            <Button
                              tag={Link}
                              to={`/user-profile/${userProfile.userId}`}
                              color="info"
                              size="sm"
                              data-cy="entityDetailsButton"
                            >
                              <FontAwesomeIcon icon="eye" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.view">View</Translate>
                              </span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="fivePlusApp.userProfile.home.notFound">No User Profiles found</Translate>
                  </div>
                )
              )}
            </div>
            {totalUsers ? (
              <div className={userProfileList.content && userProfileList.content.length > 0 ? '' : 'd-none'}>
                <div className="justify-content-center d-flex">
                  <JhiItemCount
                    page={paginationState.activePage}
                    total={totalUsers}
                    itemsPerPage={paginationState.itemsPerPage}
                    i18nEnabled
                  />
                </div>
                <div className="justify-content-center d-flex">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={totalUsers}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default UsersAdmin;
