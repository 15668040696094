import React from 'react';
import { Translate } from 'react-jhipster';
import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/header-logo.svg" alt="Logo" />
  </div>
);
const handleRedirect = () => {
  window.location.href = `https://5plus.us`;
};
export const Brand = () => (
  <NavbarBrand onClick={handleRedirect} className="brand-logo">
    <BrandIcon />
    <span className="brand-title">
      <Translate contentKey="global.title"></Translate>
    </span>
    {/* <span className="navbar-version">{VERSION}</span> */}
  </NavbarBrand>
);

export const Home = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  return isAuthenticated ? (
    <NavItem>
      <NavLink tag={Link} to="/" className="d-flex align-items-center">
        <FontAwesomeIcon icon="home" />
        <span>Dashboard</span>
      </NavLink>
    </NavItem>
  ) : null;
};
