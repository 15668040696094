import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
} from 'reactstrap';
import DocumentUploadComponent from './DocumentUpload'; // Import your DocumentUploadComponent
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntityEarnedPoints,
  getEntityPointsSummary,
  getEntityTotalPoints,
} from 'app/entities/point-allocation/point-allocation.reducer';
import { useDispatch } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import FullOption from './Fulloption';
import { CircularProgressbar } from 'react-circular-progressbar';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { getEntityAchievementsDetails } from 'app/entities/user-achievements/user-achievements.reducer';

function EarnPoints(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [state, setState] = useState(true);
  const [showView, setShowView] = useState('Manage');
  const [achievement, setAchivment] = useState('');
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [selectedOption, setSelectedOption] = useState('');
  const handleOptionClick = option => {
    setSelectedOption(option);
  };
  const account = useAppSelector(state => state.authentication.account);
  const id = account.id;
  // const userAchivementsList = useAppSelector(state => state.userAchivements.entity);
  const userAchievementsList = useAppSelector(state => state.userAchievements.entity);

  const pointAllocationEntity = useAppSelector(state => state.pointAllocation.entity);

  useEffect(() => {
    dispatch(getEntityPointsSummary(selectedOption));
    dispatch(getEntityAchievementsDetails(id));
  }, [id, selectedOption]);

  let initialPdfUrls = {};
  if (Array.isArray(userAchievementsList)) {
    initialPdfUrls = userAchievementsList.reduce((acc, ach) => {
      const achievementName = ach.achievements.name;
      const fileUrl = `/doc/${ach.fileName}`;

      if (!acc[achievementName]) {
        acc[achievementName] = [];
      }

      acc[achievementName].push(fileUrl);

      return acc;
    }, {});
  } else {
    // console.error('userAchivementsList is not an array.');
  }
  // Initialize pdfUrls with an empty object
  const [pdfUrls, setPdfUrls] = useState({});

  useEffect(() => {
    setPdfUrls(initialPdfUrls);
  }, [userAchievementsList]);
  const togglePdfModal = achievement => {
    setSelectedAchievement(achievement);
    setPdfModalOpen(!pdfModalOpen);
  };

  const handleDocumentUpload = async uploadedUrl => {
    setPdfUrls(prevUrls => ({
      ...prevUrls,
      [achievement]: [...(prevUrls[achievement] || []), uploadedUrl],
    }));
    await dispatch(getEntityPointsSummary(selectedOption));
  };
  const handleViewPdf = achievement => {
    setSelectedAchievement(achievement);
    setPdfModalOpen(true);
  };
  //console.log(pointAllocationEntity, 'pdfUrls');
  const piedata = [
    { title: 'Learn + Earn Points', value: pointAllocationEntity.learnEarnPoint, color: '#6d78ec' },
    { title: 'Joining points', value: pointAllocationEntity.joining_point, color: '#6A2135' },
  ];
  const handleearnClick = async () => {
    setShowView('earn');
  };
  const handlebackclick = async () => {
    setShowView('Manage');
  };
  const learnEarnPoint = pointAllocationEntity.learnEarnPoint || 0;
  const maxPoints = 50000;
  const percent = (pointAllocationEntity.total_points / maxPoints) * 100;

  // Generate steps based on the max points, e.g., in steps of 5000
  const steps = [0];
  const stepValue = 5000; // Adjust the step value as needed
  for (let i = stepValue; i <= maxPoints; i += stepValue) {
    steps.push(i);
  }
  return (
    <Container className="card earn-more-point">
      {showView == 'Manage' && (
        <>
          <Row className="admin-page-row ">
            <div className="col-md-8 ">
              <Row className="five-plus-points">
                <div className="fiveplus-heading-bar">
                  <h3>Points summary for 5+</h3>
                  <ButtonGroup>
                    <Button color={selectedOption === 'Month' ? 'primary' : 'secondary'} onClick={() => handleOptionClick('Month')}>
                      Month
                    </Button>
                    <Button color={selectedOption === 'Year' ? 'primary' : 'secondary'} onClick={() => handleOptionClick('Year')}>
                      Year
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="col-md-6 fiveplus-pie-chart">
                  <FullOption data={piedata} logoUrl={`/content/images/header-logo.svg`} />
                </div>

                <div className="col-md-6">
                  <div className="five-plus-points-details">
                    <ul className="five-plus-circular-points">
                      <li>
                        <CircularProgressbar
                          className="svg-learn"
                          value={100}
                          maxValue={100}
                          text={learnEarnPoint === 0 ? '0' : learnEarnPoint}
                        />
                        <p>Learn + Earn</p>
                      </li>
                      <li>
                        <CircularProgressbar className="svg-buy" value={100} maxValue={100} text={'0'} />
                        <p>Buy</p>
                      </li>
                      <li>
                        <CircularProgressbar className="svg-earn" value={100} maxValue={100} text={'0'} />
                        <p>Give</p>
                      </li>
                    </ul>
                  </div>
                  <ul>
                    <li className="list-two">Learn + Earn : As a mentee you learn. As a mentor you share your knowledge.</li>
                    <li className="list-three">Buy: if you need to top off your points or add more points</li>
                    <li className="list-four">Give: Give points to your causes to make a differece</li>
                  </ul>
                </div>
              </Row>
            </div>
            <div className="col-md-4 fiveplus-point-content">
              <h3>Points summary for 5+</h3>
              <p>
                You earn points by sharing your knowledge with others. As your points grow and reach milestones, they can be used to
                contribute to the causes you have listed in your profile and make a differece for them.
              </p>
              <div className="button-icon-learn">
                <img src="content/images/learn-earn-icon.svg" alt="icon" />
                <button className="accept-btn learn-btn" onClick={handleearnClick}>
                  {' '}
                  Learn + Earn
                </button>
              </div>
              <div className="button-icon-buy">
                <img src="content/images/buy-icon.svg" alt="icon" />
                <button className="accept-btn buy-btn" onClick={handleearnClick}>
                  {' '}
                  Buy
                </button>
              </div>
              <div className="button-icon-give">
                <img src="content/images/give-icon.svg" alt="icon" />
                <button className="accept-btn earn-btn" onClick={handleearnClick}>
                  {' '}
                  Give
                </button>
              </div>
            </div>
          </Row>
        </>
      )}

      {showView == 'earn' && (
        <>
          <div className="col-md-6 fiveplus-back-btn">
            <button onClick={handlebackclick}> Back</button>
          </div>
          <Row className="admin-page-row reward-points">
            <h3>Earn More Points</h3>
            <div className="col-md-4">
              <div className="reward-heading">
                {' '}
                <img src="content/images/reward-points-icon.png" />
                {pointAllocationEntity.total_points}
              </div>
              <p>Earn rewards points on purchases and redeem them for exciting benefits.</p>
            </div>
            <div className="col-md-8">
              <h3>Point Progress Bar</h3>
              <ProgressBar percent={percent} filledBackground="linear-gradient(to right, #fefb72, #f0bb31)">
                {steps.map((step, index) => (
                  <Step key={index} transition="scale">
                    {({ accomplished }) => {
                      // Determine if the current step is accomplished based on totalPoints
                      const isAccomplished = pointAllocationEntity.total_points >= step;

                      // Determine the appropriate image based on step value
                      let imageSrc = '/content/images/progress-point-icon.png';
                      if (step === 45000) {
                        imageSrc = '/content/images/progress-point-icon-red.png';
                      } else if (step === 30000) {
                        imageSrc = '/content/images/progress-point-icon-red.png';
                      } else if (step === 15000) {
                        imageSrc = '/content/images/progress-point-icon-red.png';
                      }

                      return (
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                          <img
                            style={{ filter: `grayscale(${isAccomplished ? 0 : 100}%)` }}
                            width="10"
                            src={imageSrc}
                            alt={`Step ${step}`}
                          />
                          <span style={{ color: isAccomplished ? '#000' : '#ccc' }}>{step}</span>
                        </div>
                      );
                    }}
                  </Step>
                ))}
              </ProgressBar>
            </div>
          </Row>
          <Row admin-page-row fiveplus-additional-points>
            <h4> Earn Additional Points</h4>
            <div className="point-age-group">
              <div className="col-md-8">
                <img src="content/images/age-group-icon-01.png" alt="icon" />
                <div className="point-content-star">
                  Acts of service to the community (verified by their community organizations)
                  <p>
                    <img src="content/images/Star.svg" alt="icon" />
                    500
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <Button
                  onClick={() => {
                    setAchivment('CommunityService');
                    toggleModal();
                  }}
                >
                  Earn Points
                </Button>
                {pdfUrls['CommunityService']?.length > 0 ? (
                  <Button onClick={() => handleViewPdf('CommunityService')}>View PDFs</Button>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="point-age-group">
              <div className="col-md-8">
                <img src="content/images/age-group-icon-02.png" alt="icon" />
                <div className="point-content-star">
                  5000 points upon acceptance to a graduate
                  <p>
                    <img src="content/images/Star.svg" alt="icon" />
                    5000
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <Button
                  onClick={() => {
                    setAchivment('Graduation');
                    toggleModal();
                  }}
                >
                  Earn Points
                </Button>
                {pdfUrls['Graduation']?.length > 0 ? <Button onClick={() => handleViewPdf('Graduation')}>View PDFs</Button> : ''}
              </div>
            </div>

            <div className="point-age-group">
              <div className="col-md-8">
                <img src="content/images/age-group-icon-03.png" alt="icon" />
                <div className="point-content-star">
                  5000 points upon acceptance to a college
                  <p>
                    <img src="content/images/Star.svg" alt="icon" />
                    500
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <Button
                  onClick={() => {
                    setAchivment('CollegeAdmission');
                    toggleModal();
                  }}
                >
                  Earn Points
                </Button>
                {pdfUrls['CollegeAdmission']?.length > 0 ? (
                  <Button onClick={() => handleViewPdf('CollegeAdmission')}>View PDFs</Button>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="point-age-group">
              <div className="col-md-8">
                <img src="content/images/age-group-icon-04.png" alt="icon" />
                <div className="point-content-star">
                  Startup (verified by their community organizations)
                  <p>
                    <img src="content/images/Star.svg" alt="icon" />
                    500
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <Button
                  onClick={() => {
                    setAchivment('StartUp');
                    toggleModal();
                  }}
                >
                  Earn Points
                </Button>
                {pdfUrls['StartUp']?.length > 0 ? <Button onClick={() => handleViewPdf('StartUp')}>View PDFs</Button> : ''}
              </div>
            </div>
          </Row>
          {/* Render the DocumentUploadComponent within the EarnPoints component */}
          <DocumentUploadComponent
            isOpen={modalOpen}
            toggle={toggleModal}
            onDocumentUpload={handleDocumentUpload}
            achievements={achievement}
          />
        </>
      )}
      <Modal className="view-pdf-popup" isOpen={pdfModalOpen} toggle={togglePdfModal}>
        <ModalHeader toggle={() => togglePdfModal(null)}>View PDFs</ModalHeader>
        <ModalBody>
          {selectedAchievement && pdfUrls[selectedAchievement] ? (
            <ul>
              {pdfUrls[selectedAchievement].map((url, index) => (
                <li key={index}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    View PDF File
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>No PDFs available for this achievement.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => togglePdfModal(null)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default EarnPoints;
