import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import FiltersDropdown from './FiltersDropdown';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getEntityFilterConnections, deleteEntityConnection, updateEntityConnection } from 'app/entities/connection/connection.reducer';
import SearchConnection from './SearchConnection';
import ConnectionMeetingDropdown from './ConnectionMeetingDropdown';
import ConnectionSugession from './ConnectionSugession';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { JhiItemCount, JhiPagination, getSortState } from 'react-jhipster';
import { getEntityConnectionsugessions } from 'app/entities/connectionsugession/connectionsugession.reducer';
export default function Connections() {
  const filterOptions = [
    { name: 'All', value: 'all' },
    { name: 'Added', value: 'accepted' },
    { name: 'SendRequest', value: 'requested' },
  ];
  const [Data, setData] = useState([]);
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const connectionEntity = useAppSelector(state => state.connection.entity);
  const loadingback = useAppSelector(state => state.connection.loading);
  const totalItems = useAppSelector(state => state.connection.totalItems);
  const connectionsugessionEntity = useAppSelector(state => state.connectionsugession.entity);
  const baseImageUrl = '/profile/get/image';
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 10, 'id', 'desc'), location.search)
  );
  const [filterType, SetFilterType] = useState('all');
  const [loading, setLoading] = useState(false);
  const handleFilterChange = async (selectedOption: string) => {
    SetFilterType(selectedOption);
    setLoading(true);
    await dispatch(getEntityFilterConnections(['connection', selectedOption]));
    setData(connectionEntity);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  // useEffect(() => {
  //   dispatch(getEntityConnectionsugessions());
  // }, []);
  // const dataArray = Object.keys(connectionsugessionEntity).map(key => ({
  //   ...connectionsugessionEntity[key],
  //   id: key,
  // }));
  // useEffect(() => {
  //   dispatch(getEntityFilterConnections(['connection', 'all']));
  //   setData(connectionEntity);
  // }, []);
  const handleDeleteButtonClick = async id => {
    setLoading(true);
    await dispatch(deleteEntityConnection(id));
    await dispatch(getEntityConnectionsugessions());
    await dispatch(getEntityFilterConnections(['connection', filterType]));
    setData(connectionEntity);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  const handleAcceptButtonClick = async id => {
    const entity = {
      id: id,
      status: 'accepted',
    };
    setLoading(true);
    await dispatch(updateEntityConnection(entity));
    //setData(connectionEntity);
    await dispatch(getEntityFilterConnections(['connection', 'all']));
    setTimeout(() => {
      //console.log('loading');
      setLoading(false);
    }, 500);
  };
  const navigate = useNavigate();

  const handleProfileClick = userId => {
    navigate(`/user-profile/${userId}`);
  };
  return (
    <Row>
      {loadingback ? (
        <Spinner className="body-loader-icon"></Spinner>
      ) : (
        <>
          <Col md="12">
            <div className="card connection-tab users-card connection-sugg">
              {connectionEntity.length === 0 && (
                <div className="connection-tab-gif">
                  <Row>
                    <div className="col-md-6">
                      <h4>Build Your Professional Network</h4>
                      <p>
                        Currently, you have no connections. Building connections is the cornerstone of successful networking. Start by
                        reaching out and connecting with peers, colleagues, and industry professionals. Dive into the world of endless
                        opportunities with every connection you make. Go to Search and start connecting with people.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <img src="content/images/connection-tab.gif" alt="Connection GIF" />
                    </div>
                  </Row>
                </div>
              )}
              {/* {Array.isArray(dataArray) && dataArray.length > 0 && <ConnectionSugession />} */}
              <div className="head-fliter">
                {Array.isArray(connectionEntity) && connectionEntity.length > 0 && (
                  <Col className="connection-all d-flex pb-3">
                    <h2 className="connection-sugg-head">Connections</h2>
                    <FiltersDropdown options={filterOptions} onChange={handleFilterChange} />
                  </Col>
                )}
              </div>
              {Array.isArray(connectionEntity)
                ? connectionEntity?.map((item, index) => (
                    <Card className="p-0 mb-2 connection-card " key={index}>
                      <CardBody className="d-flex justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <div className="me-3 connection-avtar">
                            <CardImg className="rounded-circle" src={`${baseImageUrl}/${item.imageUrl}`} />
                            <div className={` ${item.isMentor ? 'search-mentor-profile' : 'search-mentee-profile'}`}>
                              <div className="ribbon-corner">{item.isMentor ? 'Mentor' : 'Mentee'}</div>
                            </div>
                          </div>
                          <div className="profile-name-connection">
                            <CardTitle tag="h5">
                              <Link to={`/user-profile/${item.userId}`}>{item.userName}</Link>
                            </CardTitle>
                            <CardSubtitle className="mb-2 text-muted" tag="h6">
                              {item.occupation}
                            </CardSubtitle>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center pf-con-btn">
                          {item.status == 'requested' ? (
                            item.isMentor ? (
                              <Button className="pending-request-btn" disabled>
                                pending Request
                              </Button>
                            ) : (
                              <Button className="accept-request-btn" onClick={() => handleAcceptButtonClick(item.connectionId)}>
                                Accept
                              </Button>
                            )
                          ) : (
                            <></>
                          )}

                          {item.status == 'accepted' ? (
                            <div className="d-flex meeting-request">
                              {' '}
                              <ConnectionMeetingDropdown selectedFilterType={filterType} />
                              <Button className="cancel-request-btn" onClick={() => handleDeleteButtonClick(item.connectionId)}>
                                Cancel Request
                              </Button>{' '}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  ))
                : ''}
              {<ConnectionSugession />}
            </div>
          </Col>
          {/* {totalItems ? (
            <div className={connectionEntity && connectionEntity.length > 0 ? '' : 'd-none'}>
              <div className="justify-content-center d-flex">
                <JhiItemCount
                  page={paginationState.activePage}
                  total={totalItems}
                  itemsPerPage={paginationState.itemsPerPage}
                  i18nEnabled
                />
              </div>
              <div className="justify-content-center d-flex">
                <JhiPagination
                  activePage={paginationState.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={paginationState.itemsPerPage}
                  totalItems={totalItems}
                />
              </div>
            </div>
          ) : (
            ''
          )} */}
        </>
      )}
    </Row>
  );
}
