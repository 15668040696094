import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntityEarnedPoints,
  getEntityPointsSummary,
  getEntityTotalPoints,
} from 'app/entities/point-allocation/point-allocation.reducer';
import React, { useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Col } from 'reactstrap';

const TotalPoints = () => {
  const dispatch = useAppDispatch();
  const pointAllocationEntity = useAppSelector(state => state.pointAllocation.entity);
  useEffect(() => {
    //dispatch(getEntityTotalPoints());
    //dispatch(getEntityEarnedPoints());
    dispatch(getEntityPointsSummary(''));
    // console.log(pointAllocationEntity, 'pointAllocationEntity');
  }, []);
  useEffect(() => {}, [pointAllocationEntity]);

  return (
    <div className="row user-points">
      <Col xs="2">
        <div className="points-section">
          <h5 className="pb-3">Overall Points</h5>
          <CircularProgressbar
            value={pointAllocationEntity['total_points']}
            maxValue={50000}
            text={`${pointAllocationEntity['total_points']}`}
          />
        </div>
      </Col>

      <Col xs="2">
        <div className="points-section">
          <h5 className="pb-3">Earned Points</h5>
          <CircularProgressbar
            value={pointAllocationEntity['learnEarnPoint']}
            maxValue={30000}
            text={`${pointAllocationEntity['learnEarnPoint']}`}
          />
        </div>
      </Col>
    </div>
  );
};

export default TotalPoints;
