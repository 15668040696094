import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntityMenteeQuestions } from 'app/entities/question/question.reducer';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Button, Spinner } from 'reactstrap';

export const MenteeQuesions = props => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const users = useAppSelector(state => state.userManagement.users);
  const questionEntity = useAppSelector(state => state.question.entity);
  const loading = useAppSelector(state => state.question.loading);
  const updating = useAppSelector(state => state.question.updating);
  const updateSuccess = useAppSelector(state => state.question.updateSuccess);
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const [loadingMainAI, setLoadingMainAI] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const id = account.id;
  const [fields, setFields] = useState([
    { name: '', value: '', required: true },
    { name: '', value: '', required: true },
    { name: '', value: '' },
    { name: '', value: '' },
    { name: '', value: '' },
  ]);
  const handleQuesionsInputChange = (index, event) => {
    const newValue = event.target.value;
    const updatedFields = [...fields];
    updatedFields[index].value = newValue;
    updatedFields[index].name = `question_${index + 1}`;

    setFields(updatedFields);
  };
  const enhanceQuestionWithAI = async (index, retries = 3) => {
    setLoadingMainAI(true);
    try {
      const selectedQuestion = fields[index];
      const message = encodeURIComponent(selectedQuestion.value || '');

      const response = await axios.get(`/api/generate/question`, {
        params: {
          message: message,
        },
      });
      const generatedSentence = response.data;

      const updatedFields = [...fields];
      updatedFields[index].value = generatedSentence;
      setFields(updatedFields);
      setLoadingMainAI(false);
    } catch (error) {
      console.error('An error occurred while processing the request:', error);
      // Retry logic
      if (retries > 0 && error.response?.status === 406) {
        console.log(`Retrying in 20 seconds... (${retries} retries left)`);
        setTimeout(() => {
          enhanceQuestionWithAI(index, retries - 1);
        }, 20000); // Retry after 20 seconds
      } else {
        // Handle other errors or max retries reached
        console.error('Max retries reached or other error occurred.');
        // You can set loadingAI to false here if needed
      }
    }
  };
  const saveEntity = values => {
    // let filterObj = filteredObj(values);
    const filterObj: { [key: string]: string } = fields.reduce((result, field) => {
      // Check if the name starts with 'question_' and it's not an empty string
      if (field.name.startsWith('question_') && field.name !== '') {
        // Add the question to the result object with name as key
        result[field.name] = field.value;
      }
      return result;
    }, {});

    const filteredValues = Object.values(filterObj);
    filteredValues.map(async (item, index) => {
      const question = {
        user: { id: account.id },
        question: item,
      };
      await dispatch(createEntityMenteeQuestions(question));
      props.popup(false);
    });
  };
  return (
    <>
      <ValidatedForm onSubmit={saveEntity} className="row">
        {fields.map((field, index) => (
          <React.Fragment>
            <ValidatedField
              key={index}
              type="text"
              name={`question_${index}`}
              label={`${index + 1} Question For Mentor`}
              value={field.value}
              onChange={e => handleQuesionsInputChange(index, e)}
              validate={{ required: true }}
              autoFocus={index === fields.length - 1}
            />
            {loadingMainAI && loadingIndex === index ? <Spinner className="loading-btn mentee-loading-btn">Loading...</Spinner> : ''}
            <Button
              className="enhance-ai-btn mentee-enhance-ai-btn"
              onClick={() => {
                setLoadingIndex(index); // Set loading index before calling enhanceQuestionWithAI
                enhanceQuestionWithAI(index);
              }}
            >
              Enhance with AI
            </Button>
          </React.Fragment>
        ))}
        <div className="modal-que-sbt-btn">
          <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="entity.action.save">Submit</Translate>
          </Button>
        </div>
      </ValidatedForm>
    </>
  );
};
